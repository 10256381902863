(function(home, $, undefined)
{
    // cache
    var $_cardRoller;

    var cardRollerPause,
        cardRollerTimeout;


    home.init = function()
    {
        this.cardRollerPause = false;
        this.cacheInitialized = [];
        this.sections = {
            'index' : 'uiIndex'
        };

        main.loadPageEvents(jsData['subPage']);

        this.uiEvents();

        main.setStartingPushState();

        main.pollCardRoller();
    };

    home.uiEvents = function()
    {
        // cache
        $_cardRoller = $('#cardroller');


        $('#play-button').on('click', clickPlayButton);
        
        //$('#highlighted-replay').on('click', clickHighlightedReplayButton);
        //$_cardRoller.find('.cards').on('click', '.card', clickCardRollerCard);
        //$_cardRoller.find('.villages .select-village').on('click', clickCardRollerVillage);


        function clickHighlightedReplayButton()
        {
            var $_this = $(this);

            main.loadBattleReplay($_this.data('replayid'));
        }

        function clickPlayButton()
        {
            $(this).addClass('-c-clicked');
        }

        function clickCardRollerVillage()
        {
            var $_this = $(this),
                $_loader = $_cardRoller.find('.villages .loading-spin');

            $_this.addClass('sel').siblings().removeClass('sel');

            home.cardRollerPause = true;

            var sendData = {
                'Village' : $_this.data('village')
            };

            $_loader.addClass('active');

            $.ajaxHandler({
                url: '/ajax/getNinjaSelection',
                data: sendData,
                type: 'POST'
            }).done(function(data)
            {
                $_cardRoller.find('.cards').html(data);

                home.cardRollerPause = false;
                home.selectHighlightedCardRollerCard($_cardRoller.find('.card:last'));

                $_loader.removeClass('active');
            });
        }

        function clickCardRollerCard()
        {
            home.selectHighlightedCardRollerCard(this);

            // disable polling when manually clicking
            home.cardRollerPause = true;
        }
    };

    home.uiIndex = function()
    {
        main.displayNinjaGraphs($('#cardrollerStatsGraph'), []);

        setTimeout(function() {
            home.selectHighlightedCardRollerCard($_cardRoller.find('.card:last'));
        }, 500);

        main.$_centercol.find('.js-next-energy').each(function()
        {
            var $_this = $(this),
                $_countdownElement = $_this,
                energyType = $_this.data('energytype'),
                nextRefill = new Date().getTime() + (parseInt($_this.data('nextrefill')) * 1000);

            $_countdownElement.countdown(nextRefill, function(event) {
                $(this).html(event.strftime('%M:%S'));
            }).on('finish.countdown', function()
            {
                //if (energyType == 'world')
                //main.refreshHeaderTeamInfo();

                $_countdownElement.countdown(new Date().getTime() + (600 * 1000));
                
                setTimeout(function()
                {
                    var $_headerBar = $('header').find('.js-header-energy-' + energyType),
                        $_countdownParent = $_this.parent(),
                        widthPercent = $_headerBar.find('.c-bar__fill')[0].style.width,
                        energyCur = $_headerBar.find('.c-bar__text-cur').html();

                    $_countdownParent.find('.c-bar__fill').css({'width' : widthPercent});
                    $_countdownParent.find('.c-bar__text-cur').html(energyCur);
                }, 1000);
            });
        });
    };

    main.pollCardRoller = function()
    {
        if (home.cardRollerPause)
            return false;

        home.cardRollerTimeout = setTimeout(function()
        {
            if (home.cardRollerPause)
                return false;

            var $_cardNext = $_cardRoller.find('.card-sel').prev();

            home.selectHighlightedCardRollerCard($_cardNext);

            if ($_cardNext.is(':first-child'))
                return false;

            main.pollCardRoller();
        }, 8000);
    };

    home.selectHighlightedCardRollerCard = function(element)
    {
        var $_this = $(element),
            stats = $_this.data('stats');

        $_cardRoller.find('.card-sel').removeClass('card-sel').changeCardSize({
            size: 'medium',
            addClass: 'medium'
        });

        $_this.addClass('card-sel').changeCardSize({
            size: 'large',
            addClass: 'large'
        });

        if (stats)
        {
            var graphStats = main.statsToGraphConvert(stats);

            main.updateNinjaGraph('cardrollerStatsGraph', stats['GraphData'], graphStats);
        }
    };

}(window.home = window.home || {}, jQuery));

$(function()
{
    home.init();
});